<template>
  <div class="votingList_container">
    <div class="votingList_search">
      <el-input
        ref="searchRef"
        v-model="localSearch.content"
        @blur="onblur"
        @focus="onfocus"
        @input="onInput"
      ></el-input>
      <span
        @click="setFocus"
        v-if="!localSearch.content && showIcon"
        class="votingList_search_placeholder"
        ><i class="el-icon-search"></i>请输入关键词搜索...</span
      >
    </div>
    <div class="votingList_type">
      <el-select
        :popper-append-to-body="false"
        @change="selectChange()"
        v-model="search.period"
        placeholder="请选择"
      >
        <el-option
          v-for="item in config.periodList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div
        class="votingList_type_item"
        :class="{ votingList_type_item_active: localSearch.activeType }"
        @click="changeType()"
      >
        按文献点赞
      </div>
    </div>
    <div class="votingList_content">
      <div class="votingList_content_block">
        <ul
          class="ul_englishLetter"
          v-show="!localSearch.activeType"
          ref="ulRef"
        >
          <li
            :class="{ activeLetter: item == activeLetter }"
            ref="liRef"
            v-for="item in config.englishLetter"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
        <div class="scroll_block">
          <div class="select_container_block" v-show="localSearch.activeType">
            <div class="select_container" @click="openValue">
              <div class="select_block">
                <div class="select_block_icon">
                  <i v-show="!show" class="el-icon-arrow-down"></i>
                  <i v-show="show" class="el-icon-arrow-up"></i>
                </div>
                <div class="modify">
                  {{ getGroupName() }}
                </div>
              </div>
            </div>
            <div class="list" v-show="show" ref="wrapperListRef">
              <ul>
                <li
                  @click="getvalue(item)"
                  v-for="item in config.groups"
                  :key="item.group"
                >
                  {{ item.groupName }}
                </li>
              </ul>
            </div>
          </div>
          <div ref="wrapper" class="votingList_content_scroll">
            <div class="scroll_div" ref="voteItem">
              <div class="votingList_groups" v-show="localSearch.activeType">
                <div class="votingList_content_scroll_right">
                  <div
                    v-for="(item, index) in groupsData"
                    :key="item.vote_id"
                    @click="goToDetail(item)"
                  >
                    <voteCourItem
                      :item="item"
                      :index="index"
                      ref="itemRef"
                    ></voteCourItem>
                  </div>
                </div>
              </div>
              <div
                class="votingList_period"
                v-show="!localSearch.activeType"
                ref="voteList"
              >
                <div
                  @click="goToDetail(item)"
                  v-for="item in getDataList()"
                  :key="item.vote_id"
                >
                  <votingPerItem
                    ref="votingPerItemRef"
                    :item="item"
                  ></votingPerItem>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="prompt_info">
      <p>
        本项目中会传递医药前沿信息、研究进展和科学信息交流，仅供医疗卫生专业人士访问、参考及学习，请确保您的身份是医疗卫生专业人士，否则请退出。视频未经讲者允许，禁止任何形式转载。
      </p>
    </div>
  </div>
</template>
<script>
import { ElInput, ElSelect, ElOption } from "element-plus";
import voteCourItem from "./voteCourItem.vue";
import votingPerItem from "./votingPerItem.vue";
import weixin from "@/common/weixin";
import BScroll from "better-scroll";
export default {
  name: "votingList",
  components: {
    votingPerItem,
    voteCourItem,
    ElInput,
    ElSelect,
    ElOption,
  },
  data() {
    return {
      show: false,
      index: "",
      config: {
        allVoteList: [],
        periodList: [],
        myVoteList: [],
        englishLetter: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ],
        groups: [],
      },
      localSearch: {
        activeType: false,
        content: "",
        group: "",
      },
      search: {
        company_id: this.$tools.getCompany("literature", "companyId"),
        project_id: this.$tools.getCompany("literature", "projectId"),
        period: 1,
        page: 1,
        page_size: 1000,
      },
      activeLetter: "",
      showIcon: true,
      params: {},
      itemHeights: [],
      scroll: null,
    };
  },
  computed: {
    groupsData() {
      if (this.localSearch.group === "") return [];
      let index = this.localSearch.group || 1;
      let data = this.config.groups[index - 1];
      if (!data || !data.list) return [];
      let rank = 0;
      let voteCount = 0;
      let result = data.list
        .filter(
          (item) =>
            item.doctor_hospital.includes(this.localSearch.content) ||
            item.doctor_name.includes(this.localSearch.content)
        )
        .map((item) => {
          let currentVoteCount = item.vote_count;
          if (voteCount !== currentVoteCount) {
            rank = index + 1;
            voteCount = currentVoteCount;
          }
          rank = rank || 1;
          item.groupRank = rank;
          return item;
        })
        .sort((prep, next) => {
          if (prep.vote_count < next.vote_count) return 1;
          if (prep.vote_count > next.vote_count) return -1;
          return prep.doctor_letter > next.doctor_letter ? 1 : -1;
        });
      if (result.length !== 0) {
        let currentMaxVote = result[0].vote_count;
        result.map((item) => {
          if (item.vote_count === currentMaxVote) {
            item.currentRank = 1;
          }
        });
      }
      return result;
    },
  },
  created() {
    this.getStorageData();
  },
  mounted() {
    this.initScroll();
    this.config.periodList = this.formatPeriodList([1, 2, 3, 4, 5, 6, 7]);
    this.loadPeriodList();
    document.getElementsByTagName("title")[0].innerHTML = "文献讲读视频专区";
  },
  methods: {
    getStorageData() {
      let searchData = this.$tools.getStorage("searchData", sessionStorage);
      if (searchData) {
        this.search = searchData.search;
        this.localSearch = searchData.localSearch;
      }
    },
    setStorageData() {
      this.$tools.setStorage(
        "searchData",
        {
          search: this.search,
          localSearch: this.localSearch,
        },
        sessionStorage
      );
    },
    getGroupName() {
      if (!this.localSearch.group) return "";
      if (this.config.groups.length == 0) return "";
      return this.config.groups[this.localSearch.group - 1].groupName;
    },
    openValue() {
      this.show = !this.show;
      this.initListScroll();
    },
    getvalue(item) {
      this.localSearch.group = item.group;
      this.show = false;
      this.initParams();
      setTimeout(() => {
        this.initScroll();
      }, 1000);
      this.setStorageData();
    },
    async loadVoteList() {
      let url = this.$tools.getURL(this.$urls.literature.voteList, this.search);
      let res = await this.$axios.get(url);
      this.loadMyVote(res.data);
    },
    async loadMyVote(data) {
      let url = this.$tools.getURL(this.$urls.literature.myVote, this.search);
      let res = await this.$axios.get(url);
      this.config.myVoteList = res.data.filter(
        (item) =>
          this.$tools.getTime(item.subdate) >=
          this.$tools.getTime(this.$tools.getDate())
      );
      this.initData(data);
    },
    formatPeriodList(arr) {
      let numbs = [];
      arr.forEach((item) => {
        if (!item.length || item.length <= 1) {
          item = `2022-${item}`;
        }
        numbs.push(item);
      });
      return numbs.map((num) => {
        let year = num.split("-")[0];
        let month = parseInt(num.split("-")[1]);
        return {
          value: year === "2022" ? month : `${year}-${month}`,
          label: `${year}年第${this.$tools.intToChinese(month)}期`,
        };
      });

      // let list = data.map((period) => {
      //   return {
      //     value: period,
      //     label: `第${this.$tools.intToChinese(period)}期`,
      //   };
      // });
      // return list;
    },
    async loadPeriodList() {
      let url = this.$tools.getURL(
        this.$urls.literature.periodList,
        this.search
      );
      let res = await this.$axios.get(url);
      this.config.periodList = this.formatPeriodList(res.data);
      let data = this.$tools.getStorage("searchData", sessionStorage);
      if (this.$route.query.group) {
        this.localSearch.group = Number(this.$route.query.group);
        this.localSearch.activeType = true;
      }
      this.search.period =
        Number(this.$route.query.period) ||
        (data && data.search.period) ||
        [...this.config.periodList].shift().value;
      this.loadVoteList();
    },
    initData(data) {
      let voteConfig = {};
      this.config.myVoteList.forEach((item) => {
        voteConfig[item.vote_id] = voteConfig[item.vote_id] || 0;
        voteConfig[item.vote_id] = voteConfig[item.vote_id] += 1;
      });
      let totalCount = data.reduce((total, item) => total + item.vote_count, 0);
      let tempGroup = [];
      let rank = 0;
      let voteCount = 0;
      this.config.allVoteList = data
        .sort((prep, next) => (prep.vote_count < next.vote_count ? 1 : -1))
        .map((item, index) => {
          if (!tempGroup.includes(item.courseware_name))
            tempGroup.push(item.courseware_name);
          let currentVoteCount = item.vote_count;
          if (voteCount !== currentVoteCount) {
            rank = index + 1;
            voteCount = currentVoteCount;
          }
          rank = rank || 1;
          item.rank = rank;
          item.voteForMeCount = voteConfig[item.id] || 0;
          item.totalCount = totalCount;
          item.class_group =
            item.class_group || tempGroup.indexOf(item.courseware_name) + 1;
          return item;
        });
      this.config.allVoteList = this.config.allVoteList.sort((prep, next) =>
        prep.doctor_letter > next.doctor_letter ? 1 : -1
      );
      let groups = [];
      this.config.allVoteList.forEach((item) => {
        let group = item.class_group || 1;
        let current = groups[group - 1] || {
          group,
          list: [],
          classGroupImage: item.class_group_image,
          groupName: `第${this.$tools.intToChinese(group)}组：${
            item.courseware_name
          }`,
          coursewareName: item.courseware_name,
        };
        current.list.push(item);
        groups[group - 1] = current;
      });
      this.config.groups = groups.filter((x) => x);
      this.$nextTick(() => {
        this.initScroll();
        this.getItemHeight();
        this.initParams();
        this.dealWithScroll({ y: 0 });
      });
    },
    initParams() {
      let title = "";
      let logo = "";
      if (this.localSearch.activeType) {
        let group = this.localSearch.group - 1;
        let item = this.config.groups[group];
        title = `【“产科速讯”文献病例分享会】 ${item.coursewareName}`;
        logo = item.classGroupImage;
      } else {
        title = "【“产科速讯”文献病例分享会】";
      }
      let search = {
        period: this.search.period,
      };
      if (this.localSearch.activeType) {
        search.group = this.localSearch.group;
      }
      this.params = {
        title,
        desc: `我们是【“产科速讯”文献病例分享会】医生。\n快来为我们点赞加油吧!`,
        url: this.$tools.getURL(
          `${this.$config.host}/#/literature/votingList`,
          search
        ),
        logo,
      };
      setTimeout(() => {
        this.shareMessage();
      }, 1000);
    },
    shareMessage() {
      weixin.shareMessage(this.params);
    },
    getDataList() {
      return this.config.allVoteList.filter(
        (item) =>
          item.doctor_hospital.includes(this.localSearch.content) ||
          item.doctor_name.includes(this.localSearch.content)
      );
    },
    getItemHeight() {
      this.$nextTick(() => {
        if (!this.$refs.voteList) return;
        let childrens = this.$refs.voteList.children;
        this.itemHeights = [...childrens].map((item) => item.clientHeight);
      });
    },
    initScroll() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.wrapper, {
            click: true,
            probeType: 3,
            mouseWheel: true,
          });
          this.scroll.on("scroll", (pos) => {
            this.dealWithScroll(pos);
          });
        } else {
          this.scroll.refresh();
          this.scroll.scrollTo(0, 0);
        }
      });
    },
    initListScroll() {
      this.$nextTick(() => {
        if (!this.scrollList) {
          this.scrollList = new BScroll(this.$refs.wrapperListRef, {
            click: true,
            probeType: 3,
            mouseWheel: true,
            scrollbar: {
              fade: false,
            },
          });
        } else {
          this.scrollList.refresh();
        }
      });

      this.$nextTick(() => {});
    },
    dealWithScroll(pos) {
      if (this.localSearch.activeType || this.itemHeights.length == 0) return;
      let count = 0;
      let currentIndex = 0;
      let index = 0;
      while (index < this.itemHeights.length) {
        count += this.itemHeights[index];
        if (count > Math.abs(pos.y)) {
          currentIndex = index;
          break;
        }
        index++;
      }
      let list = this.getDataList();
      this.activeLetter = list[currentIndex].doctor_letter;
    },
    goToDetail(item) {
      this.setStorageData();
      this.$router.push({
        path: "/literature/voteDetail",
        query: {
          vote_id: item.id,
        },
      });
    },
    selectChange() {
      this.localSearch.activeType = false;
      this.show = false;
      if (this.localSearch) this.localSearch.group = 1;
      this.loadVoteList();
      setTimeout(() => {
        this.initScroll();
      }, 1000);
      this.setStorageData();
    },
    onInput() {
      this.$nextTick(() => {
        this.initScroll();
        this.getItemHeight();
      });
      if (this.localSearch.content == "") {
        this.$refs.liRef.forEach((v) => (v.className = ""));
      }
    },
    setFocus() {
      this.showIcon = false;
      this.$refs.searchRef.focus();
    },
    onblur() {
      this.$refs.searchRef.blur();
      this.showIcon = true;
    },
    onfocus() {
      this.showIcon = false;
    },
    changeType() {
      this.localSearch.activeType = !this.localSearch.activeType;
      this.show = false;
      if (this.localSearch) {
        this.localSearch.group = 1;
      } else {
        this.getItemHeight();
      }
      this.$nextTick(() => {
        this.initScroll();
        this.getItemHeight();
        this.initParams();
      });
      // setTimeout(() => {
      //   this.getItemHeight();
      //   this.initParams();
      // }, 1000);
      this.localSearch = { ...this.localSearch };
      this.setStorageData();
    },
  },
};
</script>
<style >
.votingList_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f2f2f3;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.votingList_search {
  padding: 0 5.33vw;
  width: 100%;
  height: 6.67vw;
  position: relative;
  color: #dcdfe6;
  margin-top: 2.67vw;
  display: flex;
  align-items: center;
  flex: none;
}
.votingList_search input {
  font-size: 3.2vw !important;
  padding-left: 7vw;
  height: 6.67vw;
  line-height: 6.67vw;
  border: none;
  text-align: center !important;
  border-radius: 4vw;
}
.votingList_search_placeholder {
  font-size: 3.2vw;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #c6c8c7;
  display: flex;
  align-items: center;
  justify-content: center;
}
.votingList_search_placeholder i {
  margin-right: 1.33vw;
  color: #5f5f5f;
}
.votingList_type {
  padding: 0 5.33vw;
  margin-top: 4vw;
  flex: none;
  display: flex;
  justify-content: space-between;
}
.votingList_type .el-input__inner {
  border-radius: 4vw;
  height: 5.33vw;
  line-height: 5vw;
  width: 22.67vw;
  background: #80b25f;
  color: #fff;
  font-size: 3.2vw !important;
  border: none;
  text-align: center !important;
  padding: 0;
  padding-right: 0 !important;
}
.votingList_type .el-select-dropdown__item.selected {
  color: #80b25f;
}
.votingList_type .el-select-dropdown__item.hover {
  background: #fff;
}
.votingList_type .el-input__suffix {
  display: none;
}
.votingList_content {
  flex: 1 0 0;
  width: 100%;
  font-size: 5vw;
  overflow: hidden;
  margin-top: 6.67vw;
}
.votingList_content_block {
  height: 100%;
  display: flex;
}
.votingList_content .scroll_block {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.votingList_content_scroll {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.votingList_container .prompt_info {
  vertical-align: middle;
  padding: 2.67vw 5.33vw;
  background: #e5eae2;
  font-size: 2vw;
  flex: none;
  display: flex;
  align-items: center;
}
.votingList_container .prompt_info p {
  line-height: 3.5vw;
}
.votingList_container .ul_englishLetter {
  list-style: none;
  height: 100%;
  overflow-y: scroll;
  width: 3vw;
  flex: none;
  padding: 0 5.33vw;
}
.votingList_container .ul_englishLetter li {
  text-align: center;
  font-size: 2.13vw;
  line-height: 5vw;
}
.votingList_container .activeLetter {
  color: #80b25f;
  font-size: 3.6vw !important;
}
.votingList_type_item {
  font-size: 3.2vw;
  height: 5.33vw;
  line-height: 1vw;
  width: 22.67vw;
  border-radius: 5.33vw;
  background-color: #909499;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.votingList_type_item_active {
  background-color: #80b25f;
}
.votingList_container .select_container_block {
  padding: 0 5.33vw;
}
.votingList_container .select_container {
  width: 100%;
  height: auto;
  position: relative;
  padding: 2vw 8vw 2vw 3vw;
  background: #fff;
  border-radius: 2vw;
  margin-bottom: 3vw;
}
.votingList_container .select_block {
  position: relative;
}
.votingList_container .modify {
  min-height: 6.67vw;
  position: relative;
  word-break: break-all;
  line-height: 5vw;
  font-size: 3.5vw;
  color: #80b25f;
}
.votingList_container .select_block_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  right: -7vw;
  color: #909499;
}
.votingList_container .list {
  margin-top: 2vw;
  max-height: 60vw;
  width: 90vw;
  background: #fff;
  border-radius: 2vw;
  position: absolute;
  z-index: 22;
  overflow: hidden;
}
.list .bscroll-indicator {
  width: 1vw !important;
  height: 13.33vw !important;
}
.votingList_container .list ul li {
  line-height: 4.2vw;
  font-size: 3.2vw;
  width: 100%;
  border: none;
  outline: none;
  padding: 2vw;
}
.votingList_container .el-select-dropdown__item:active {
  background: #fff;
}
.votingList_container .el-select-dropdown__item:hover {
  background: #fff;
}
.votingList_container .selected {
  background: #fff !important;
}
</style>