<template>
  <div class="voteCourItem_container">
    <div
      class="voteCourItem_doctorInfo"
      :class="{ voteCourItem_doctorInfo_active: data.voteForMeCount == 1 }"
    >
      <div>{{ index + 1 }}</div>
      <div class="avater_content">
        <img :src="data.profile" class="voteCourItem_avater" />
        <!-- <img
          v-if="data.currentRank == 1 && data.vote_count !== 0"
          :src="require('@/assets/images/literature/trophy.png')"
          class="voteCourItem_trophy"
        /> -->
      </div>
      <div class="voteCourItem_Info">
        <p>{{ data.doctor_hospital }} {{ data.doctor_name }}</p>
        <div class="voteCourItem_Info_courname">
          {{ data.courseware_name }}
        </div>
      </div>
      <div class="votingItem_attention">
        <img ref="imgRef" :src="getImage()" class="votingItem_attention_img" />
        <span>{{ data.vote_count }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    index: Number,
  },
  created() {
    this.loadData();
  },
  watch: {
    item() {
      this.loadData();
    },
  },
  methods: {
    loadData() {
      this.data = { ...this.item };
      this.data.vote_count = this.data.vote_count ? this.data.vote_count : 0;
    },
    getImage() {
      let name = this.data.voteForMeCount ? "attention-1" : "attention";
      return require(`@/assets/images/literature/${name}.png`);
    },
  },
};
</script>

<style >
.voteCourItem_container {
  width: 100%;
  margin: 2.67vw 0;
  background: #f2f2f3;
  font-size: 3vw;
  border-radius: 2vw;
}
.voteCourItem_doctorInfo {
  padding: 0 5.33vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.voteCourItem_doctorInfo_active {
  background-image: linear-gradient(to right, #dbe5d5, rgb(242, 242, 243));
}

.avater_content {
  position: relative;
}
.voteCourItem_avater {
  width: 16vw;
  height: 16vw;
  margin: 0 2.67vw;
  border-radius: 50%;
  border: 1px solid #80b25f;
  background: #fff !important;
}
.voteCourItem_trophy {
  width: 4.67vw;
  height: 4.67vw;
  position: absolute;
  top: 0;
  left: 2.4vw;
}
.voteCourItem_Info {
  height: 16vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.votingItem_attention {
  display: flex;
  align-items: center;
  color: #8d8e8d;
  margin-left: 2.67vw;
}
.votingItem_attention_img {
  width: 4vw;
  height: 4vw;
  margin-right: 2.67vw;
}
.votingItem_attention span {
  width: 6vw;
  text-align: right;
}
.voteCourItem_Info_courname {
  height: 8vw;
  line-height: 4.2vw;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
</style>